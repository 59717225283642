import React from 'react'
// import netlifyInit from '../netlify/init'

export default class AdminPage extends React.Component {
  componentDidMount() {
    require('../netlify/init')
    require('netlify-cms')
    window.initCMS()
  }
  render() {
    return <div />
  }
}
